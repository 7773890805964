<template>
  <div>
    <h1 class='text-2xl mb-16'>
      {{reportName}}
    </h1>
    <div class='mb-12'>
      <a class='hover:bg-gray-100 rounded-md py-2 px-8 border' :href='announcementLink' target='_blank'>Go To Dart Page</a>
      <button class='hover:bg-gray-100 rounded-md ml-4 py-2 px-8 border' @click='createInvestmentCard'>Card생성</button>
    </div>
    <div class='mb-12'>
      <label class='form-label'>종목</label>
      <div>{{ drilldownCorporateAnnouncement.name }} ({{ drilldownCorporateAnnouncement.share_code }})</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>권리유형</label>
      <div>{{ drilldownCorporateAnnouncement.action }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>회차</label>
      <div>{{ drilldownCorporateAnnouncement.details['회차'] }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>액면</label>
      <div>{{ numberStyle(drilldownCorporateAnnouncement.details['액면']) }} 원</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>표면이자율</label>
      <div>{{ numberStyle(drilldownCorporateAnnouncement.details['표면이자율']) }} %</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>만기이자율</label>
      <div>{{ numberStyle(drilldownCorporateAnnouncement.details['만기이자율']) }} %</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>사채만기일</label>
      <div>{{ drilldownCorporateAnnouncement.details['사채만기일'] }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>행사가</label>
      <div>{{ numberStyle(drilldownCorporateAnnouncement.details['전환가액']) }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>전환청구시작일</label>
      <div>{{ drilldownCorporateAnnouncement.details['전환청구시작일'] }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>전환청구종료일</label>
      <div>{{ drilldownCorporateAnnouncement.details['전환청구종료일'] }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>납입일</label>
      <div>{{ drilldownCorporateAnnouncement.details['납입일'] }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>인수자</label>
      <div class='container whitespace-pre-line'>{{ this.drilldownCorporateAnnouncement.details['발행내역'].join('\n') }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>Receipt Date</label>
      <div>{{ drilldownCorporateAnnouncement.receipt_date }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>Receipt Number</label>
      <div>{{ drilldownCorporateAnnouncement.receipt_number }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CorporateAnnouncementBondDetails',
  computed: {
    reportName () {
      return this.drilldownCorporateAnnouncement.report_name
    },
    ...mapState('corporateAnnouncements', [
      'drilldownCorporateAnnouncement',
    ]),
    ...mapState('entities', [
      'entities'
    ]),
    announcementLink () {
      return this.drilldownCorporateAnnouncement.url ? this.drilldownCorporateAnnouncement.url : ''
    },
    entity () {
      return this.entities.find(entity => entity.entity_name === this.drilldownCorporateAnnouncement.name)
    },
    totalQuantity () {
      return Number(this.drilldownCorporateAnnouncement.details['보통주']) + Number(this.drilldownCorporateAnnouncement.details['우선주']) + Number(this.drilldownCorporateAnnouncement.details['기타주'])
    },
    totalAmount () {
      return Number(this.drilldownCorporateAnnouncement.details['시설자금']) + Number(this.drilldownCorporateAnnouncement.details['영업양수자금']) + Number(this.drilldownCorporateAnnouncement.details['운영자금']) +
            Number(this.drilldownCorporateAnnouncement.details['채무상환자금']) + Number(this.drilldownCorporateAnnouncement.details['타법인증권취득자금']) + Number(this.drilldownCorporateAnnouncement.details['기타자금'])
    }
  },
  methods: {
    ...mapActions('entities', [
      'createEntityWithName',
      'getEntities',
    ]),
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('investments', [
      'createInvestment',
    ]),
    createInvestmentCard () {
      // TODO: get smarter and dont recreate cards even on announcement updates
      // and smarter entity finding
      let investment = {}
      if (this.entity === undefined) {
        this.createEntityWithName(this.drilldownCorporateAnnouncement.name).then(resp => {
          investment.investment_name = resp.entity_name + ' - ' + this.drilldownCorporateAnnouncement.asset_class
          investment.entity_id = resp.id
          this.createInvestment(investment)
        })
      } else {
        investment.investment_name = this.entity.entity_name + ' - ' + this.drilldownCorporateAnnouncement.asset_class
        investment.entity_id = this.entity.id
        this.createInvestment(investment)
      }
    },
    doneEditing () {
      this.sidepanelClose()
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
  },
  mounted () {
    this.getEntities()
  }
}
</script>

<style lang='scss' scoped>

.form-label {
  @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
}


</style>
